import React, { Component } from 'react'
import { Scene } from '../../scenes/Scene.jsx'

import { Col, Container, Nav, NavLink, Row } from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Routes as AppRoutes, TabGroups, Text } from '../../Constants.jsx'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Quotes extends Component {
  render () {
    const location = this.props.match.location.pathname
    let key = 0
    const title = Text.TITLE

    const tabRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.QUOTES) {
        tabRoutes.push(appRoute)
      }
    })

    const quotes = this.props.route.data

    return (
      <Scene>
        <Container fluid='fluid'>
          <Metadata title={title} path={location} description={quotes.intro.desc} keywords={quotes.intro.heading} />
          <br />
          <Nav tabs={true}>
            {
            tabRoutes.map(appRoute => (
              <NavLink
                key={key++} tag={Link} to={appRoute.path} className={classnames({
                  active: location === appRoute.path
                })}
              >
                <FontAwesomeIcon icon='angle-double-right' />{appRoute.tabMetaTitle}
              </NavLink>))
          }
          </Nav>
          <h1>{quotes.intro.heading}</h1>
          <p>{quotes.intro.desc}</p>
          <br /> {
          quotes.list.map(quote => (
            <Row key={key++}>
              <Col sm='2' />
              <Col sm='8' className='text-left'>
                <p>
                  <strong>{quote.text}</strong>
                  <br />
                &nbsp; &nbsp; - {quote.author}
                </p>
                <hr />
              </Col>
              <Col sm='2' />
            </Row>))
        }
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Quotes)
