import React, { Component } from 'react'

export class MultiLineText extends Component {
  render () {
    const text = this.props.text
    return (
      <div>
        {
        text.split('\n').map((i, key) => {
          return <p key={key}>{i}</p>
        })
      }
      </div>
    )
  }
}
