import React, { Component } from 'react'
import { Scene } from './Scene.jsx'

import { Col, Container, Nav, NavLink, Row } from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Routes as AppRoutes, TabGroups } from '../Constants.jsx'
import { withRouter } from '../common-react-client/components/Utilities.jsx'
import { Metadata } from '../common-react-client/components/Metadata.jsx'
import { MultiLineText } from '../common-react-client/components/MultiLineText.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Main extends Component {
  render () {
    const location = this.props.match.location.pathname
    let key = 0
    let title = 'BJJ Zone'

    const appRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.MAIN) {
        appRoutes.push(appRoute)
      }
    })

    const bjjs = this.props.route.data

    return (
      <Scene>
        <Container fluid>
          <Metadata title={title} path={location} description={bjjs.intro.desc} keywords={bjjs.intro.heading} />
          <br />
          <Nav tabs='tabs'>
            {
            appRoutes.map(appRoute => (<NavLink
              key={key++} tag={Link} to={appRoute.path} className={classnames({
                active: location === appRoute.path || location === appRoute.homepath
              })}
                                     >
              <FontAwesomeIcon icon='angle-double-right' />{appRoute.tabMetaTitle}
                                     </NavLink>))
          }
          </Nav>
          <h1>{bjjs.intro.heading}</h1>
          <Row key={key++}>
            <Col sm='1' />
            <Col sm='10'>
              <div className='text-left'>
                <MultiLineText text={bjjs.intro.desc} />
              </div>
            </Col>
            <Col sm='1' />
          </Row>
          <br /> {
          bjjs.list.map(bjj => (<Row key={key++}>
            <Col sm='2' />
            <Col sm='8' className='text-left'>
              <p>
                <strong><MultiLineText text={bjj.text} /></strong>
                <MultiLineText text={bjj.desc} />
              </p>
              <hr />
            </Col>
            <Col sm='2' />
                                </Row>))
        }
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Main)
