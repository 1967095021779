import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBattleNet,
  faInstagram,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

import {
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faBookReader,
  faLink,
  faMicrophone,
  faQuoteLeft,
  faQuoteRight,
  faSkullCrossbones,
  faTools,
  faUserFriends,
  faVideo
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faBattleNet,
  faInstagram,
  faTwitter
)

library.add(
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faBookReader,
  faLink,
  faMicrophone,
  faQuoteLeft,
  faQuoteRight,
  faSkullCrossbones,
  faTools,
  faUserFriends,
  faVideo
)
// library.add(faTwitter, faInstagram);
