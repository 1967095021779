import classnames from 'classnames'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Container,
  Media,
  Nav,
  NavLink,
  Row
} from 'reactstrap'
import { Scene } from '../../scenes/Scene.jsx'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'
import { Rating } from '../components/Rating.jsx'
import { Amazon, Routes as AppRoutes, TabGroups, Text } from '../../Constants.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Books extends Component {
  handleClick (event) {
    window.open(event.target.value, '_blank')
  }

  render () {
    // console.log('this.props=' + JSON.stringify(this.props))

    const location = this.props.match.location.pathname
    let key = 0
    const title = Text.TITLE

    const tabRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.BOOKS) {
        tabRoutes.push(appRoute)
      }
    })

    const books = this.props.route.data

    return (
      <Scene>
        <Container fluid='fluid'>
          <Metadata title={title} path={location} description={books.intro.desc} keywords={books.intro.heading} />
          <br />
          <Nav tabs={true}>
            {
            tabRoutes.map(appRoute => (
              <NavLink
                key={key++} tag={Link} to={appRoute.path} className={classnames({
                  active: location === appRoute.path
                })}
              >
                <FontAwesomeIcon icon='angle-double-right' />{appRoute.tabMetaTitle}
              </NavLink>))
          }
          </Nav>
          <h1>{books.intro.heading}</h1>
          <p>{books.intro.desc}</p>
          <br />
          <h2>
            <p>
              <a
                href={Amazon.FIND_IT_URL} target='_blank' rel='noopener noreferrer' style={{
                  textDecoration: 'none'
                }}
              >Find it on Amazon<sup>*</sup>
              </a>
            </p>
          </h2>
          <br />
          <br /> {
          books.list.map(book => (
            <Row key={key++}>
              <Col sm='4'>
                <a href={book.url} target='_blank' rel='noopener noreferrer'>
                  <Media src={book.image} height='200' width='150' alt={book.title} className='float-right' />
                </a>
                <img
                  src={book.webtrack} border='0' height='1' width='1' alt='' style={{
                    border: 'none',
                    margin: '0px'
                  }}
                />
              </Col>
              <Col sm='8' className='text-left'>
                <p>
                  <a href={book.url} target='_blank' rel='noopener noreferrer'>
                    <strong>{book.title}</strong>
                  </a>
                </p>
                <p>Author: {book.author}</p>
                <p>Desc: {book.desc}</p>
                <p>Rating(1-5):
                  <Rating rating={book.rating} />
                </p>
                <p>
                  <Button outline='outline' className='btn Extlink' onClick={this.handleClick} value={book.url}>View in Amazon</Button>
                </p>
                <br />
                <hr />
                <br />
              </Col>
            </Row>))
        }
          <div>
            <br />
            <br />
            <strong>(*)As an Amazon Associate this site earns from qualifying purchases. Your support is appreciated.</strong>
          </div>
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Books)
