import React, { Component } from 'react'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'
import { Scene } from '../../scenes/Scene.jsx'
import { Text, Routes as AppRoutes, TabGroups } from '../../Constants.jsx'

import {
  Button,
  Col,
  Container,
  Media,
  Nav,
  NavLink,
  Row
} from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Rating } from '../components/Rating.jsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Podcasts extends Component {
  handleClick (event) {
    window.open(event.target.value, '_blank')
  }

  render () {
    console.log('this.props=' + JSON.stringify(this.props))

    const location = this.props.match.location.pathname
    let key = 0
    const title = Text.TITLE

    const tabRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.PODCASTS) {
        tabRoutes.push(appRoute)
      }
    })

    const podcasts = this.props.route.data

    return (
      <Scene>
        <Container fluid='fluid'>
          <Metadata title={title} path={location} description={podcasts.intro.desc} keywords={podcasts.intro.heading} />
          <br />
          <Nav tabs={true}>
            {
            tabRoutes.map(appRoute => (
            <NavLink
              key={key++} tag={Link} to={appRoute.path} className={classnames({
                active: location === appRoute.path
              })}
                                       >
              <FontAwesomeIcon icon='angle-double-right' />{appRoute.tabMetaTitle}
            </NavLink>))
          }
          </Nav>
          <h1>{podcasts.intro.heading}</h1>
          <p>{podcasts.intro.desc}</p>
          <br /> {
          podcasts.list.map(podcast => (
          <div key={++key}>
            <Row key={key++}>
              <Col sm='4'>
                <a href={podcast.home} target='_blank' rel='noopener noreferrer'>
                  <Media src={podcast.image} height='200' width='150' alt={podcast.title} className='float-right' />
                </a>
              </Col>
              <Col sm='8' className='text-left'>
                <p>
                  <a href={podcast.home} target='_blank' rel='noopener noreferrer'>
                    <Media src={podcast.image} height='200' width='150' alt={podcast.title} className='float-right' />
                  </a>
                </p>
                <p>Author: {podcast.author}</p>
                <p>Desc: {podcast.desc}</p>
                <p>Rating(1-5):
                  <Rating rating={podcast.rating} />
                </p>
                <p>
                  {podcast.listen.map(site => (<Button key={++key} outline className='btn ExtLink' onClick={this.handleClick} value={site.url}>Listen in {site.type}</Button>))}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <hr />
                <br />
              </Col>
            </Row>
          </div>))
        }
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Podcasts)
