import Beastoic from './scenes/Beastoic.jsx'
import Daam from './scenes/Daam.jsx'
import Gear from './scenes/Gear.jsx'
import Main from './scenes/Main.jsx'

import About from './common-react-client/scenes/About.jsx'
import Books from './common-react-client/scenes/Books.jsx'
import Contact from './common-react-client/scenes/Contact.jsx'
import NotFound from './common-react-client/scenes/NotFound.jsx'
import Podcasts from './common-react-client/scenes/Podcasts.jsx'
import Privacy from './common-react-client/scenes/Privacy.jsx'
import Quotes from './common-react-client/scenes/Quotes.jsx'
import TermsOfUse from './common-react-client/scenes/TermsOfUse.jsx'
import Videos from './common-react-client/scenes/Videos.jsx'

export const Amazon = {
  FIND_IT_URL: 'https://www.amazon.com/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=beastoic-20&linkId=5f29838b3fd21c4eca0150363af1b00f&language=en_US'
}

export const Site = {
  INSTAGRAM_URL: 'http://instagram.com/bjj_zone',
  TWITTER_URL: 'http://twitter.com/bjj_zone',
  URL: 'https://bjj.zone'
}

export const Text = {
  ABBREVIATION: 'BBJZONE',
  DESCRIPTION: 'We are here to help you advance on your Brazilian Jiu Jitsu journey.',
  EMAIL_CONTACT: 'contact@bjj.zone',
  KEYWORDS: 'bjj, gear, learning, techniques',
  TITLE: 'Brazilian Jiu Jitsu Zone'
}

export const TabGroups = {
  BOOKS: 'books',
  GEAR: 'gear',
  MAIN: 'main',
  NONE: 'n/a',
  OTHER: 'other',
  PODCASTS: 'podcasts',
  QUOTES: 'quotes',
  VIDEOS: 'videos'
}

export const Paths = {
  ABOUT: '/about/',
  BEASTOIC: '/other/beastoic/',
  BOOKS_STARTING: '/books/starting/',
  BOOKS_ADVANCED: '/books/advanced/',
  CONTACT: '/contact/',
  DAAM: '/other/daam/',
  GEAR_GI: '/gear/gi/',
  HOME: '/',
  MAIN_BJJ: '/main/bjj/',
  NOT_FOUND: '/*',
  PODCASTS_BJJ: '/podcasts/bjj/',
  PRIVACY: '/privacy/',
  QUOTES_BJJ: '/quotes/bjj/',
  TERMS_OF_USE: '/terms_of_use/',
  VIDEOS_BJJ: '/videos/bjj/'
}

export const Routes = [
  {
    path: Paths.ABOUT,
    component: About,
    tabGroup: TabGroups.NONE
  }, {
    path: Paths.BEASTOIC,
    component: Beastoic,
    tabGroup: TabGroups.OTHER
  }, {
    path: Paths.DAAM,
    component: Daam,
    tabGroup: TabGroups.OTHER
  }, {
    path: Paths.CONTACT,
    component: Contact,
    tabGroup: TabGroups.NONE
  }, {
    path: Paths.PRIVACY,
    component: Privacy,
    tabGroup: TabGroups.NONE
  }, {
    path: Paths.TERMS_OF_USE,
    component: TermsOfUse,
    tabGroup: TabGroups.NONE
  }, {
    path: Paths.BOOKS_STARTING,
    component: Books,
    data: require('./data/books-starting.json'),
    tabGroup: TabGroups.BOOKS,
    tabMetaTitle: 'Books - Starting'
  }, {
    path: Paths.BOOKS_ADVANCED,
    component: Books,
    data: require('./data/books-advanced.json'),
    tabGroup: TabGroups.BOOKS,
    tabMetaTitle: 'Books - Advanced'
  }, {
    path: Paths.GEAR_GI,
    component: Gear,
    data: require('./data/gear-gi.json'),
    tabGroup: TabGroups.GEAR,
    tabMetaTitle: 'Gear'
  }, {
    path: Paths.MAIN_BJJ,
    homepath: Paths.HOME,
    component: Main,
    data: require('./data/main-bjj.json'),
    tabGroup: TabGroups.MAIN,
    tabMetaTitle: 'BJJ'
  }, {
    path: Paths.PODCASTS_BJJ,
    component: Podcasts,
    data: require('./data/podcasts-bjj.json'),
    tabGroup: TabGroups.PODCASTS,
    tabMetaTitle: 'Podcasts'
  }, {
    path: Paths.QUOTES_BJJ,
    component: Quotes,
    data: require('./data/quotes-bjj.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Quotes'
  }, {
    path: Paths.VIDEOS_BJJ,
    component: Videos,
    data: require('./data/videos-bjj.json'),
    tabGroup: TabGroups.VIDEOS,
    tabMetaTitle: 'Videos'
  }, {
    path: Paths.HOME,
    homepath: Paths.HOME,
    component: Main,
    data: require('./data/main-bjj.json'),
    tabGroup: TabGroups.NONE,
    tabMetaTitle: 'Welcome'
  }, {
    path: Paths.NOT_FOUND,
    component: NotFound,
    tabGroup: TabGroups.NONE
  }
]
