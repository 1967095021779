import React, { Component } from 'react'
import { NavMenu as Header } from '../common-react-client/components/NavMenu.jsx'
import { Footer } from '../common-react-client/components/Footer.jsx'
import { Link } from 'react-router-dom'
import { Paths } from '../Constants.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class Scene extends Component {
  render () {
    return (
      <div>
        <Header />

        <Link to={Paths.MAIN_BJJ}>
          <FontAwesomeIcon icon='skull-crossbones' />BJJ
        </Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.BOOKS_STARTING}><FontAwesomeIcon icon='book-reader' />&nbsp;Books</Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.QUOTES_BJJ}><FontAwesomeIcon icon='quote-left' />&nbsp;Quotes&nbsp;<FontAwesomeIcon icon='quote-right' /></Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.PODCASTS_BJJ}><FontAwesomeIcon icon='microphone' />&nbsp;Podcasts</Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.GEAR_GI}><FontAwesomeIcon icon='tools' />&nbsp;Gear</Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.VIDEOS_BJJ}><FontAwesomeIcon icon='video' />&nbsp;Videos</Link>

        {this.props.children}

        <Footer />
      </div>
    )
  }
}
