import classnames from 'classnames'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Container,
  Media,
  Nav,
  NavLink,
  Row
} from 'reactstrap'
import { Scene } from './Scene.jsx'
import { Metadata } from '../common-react-client/components/Metadata.jsx'
import { Rating } from '../common-react-client/components/Rating.jsx'
import { MultiLineText } from '../common-react-client/components/MultiLineText.jsx'
import { Routes as AppRoutes, TabGroups } from '../Constants.jsx'
import { withRouter } from '../common-react-client/components/Utilities.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Gear extends Component {
  handleClick (event) {
    window.open(event.target.value, '_blank')
  }

  render () {
    const gears = this.props.route.data
    const location = this.props.match.location.pathname
    let key = 0
    let title = 'BJJ Zone'

    const appRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.GEAR) {
        appRoutes.push(appRoute)
      }
      // console.log("route=" + JSON.stringify(appRoute));
      if (location === appRoute.path) {
        title = appRoute.tabMetaTitle
      }
    })

    return (
      <Scene>
        <Container fluid>
          <Metadata title={title} path={location} description={gears.intro.desc} keywords={gears.intro.heading} />
          <br />
          <Nav tabs>
            {
            appRoutes.map(appRoute => (<NavLink
              key={key++} tag={Link} to={appRoute.path} className={classnames({
                active: location === appRoute.path || location === appRoute.homepath
              })}
                                     >
              <FontAwesomeIcon icon='angle-double-right' />{appRoute.tabMetaTitle}
                                     </NavLink>))
          }
          </Nav>
          <h1>{gears.intro.heading}</h1>
          <p>{gears.intro.desc}</p>
          <br />
          <h2>
            <a href='https://www.amazon.com/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=bjj-20&linkId=5f29838b3fd21c4eca0150363af1b00f&language=en_US' target='_blank' rel='noopener noreferrer'>Find it on Amazon<sup>*</sup>
            </a>
          </h2>
          <br />
          <br /> {
          gears.list.map(gear => (<Row key={key++}>
            <Col sm='4'>
              <a href={gear.url} target='_blank' rel='noopener noreferrer'>
                <Media src={gear.image} height='200' width='200' alt={gear.title} className='float-right' />
              </a>
              <img
                src={gear.webtrack} border='0' height='1' width='1' alt='' style={{
                  border: 'none',
                  margin: '0px'
                }}
              />
            </Col>
            <Col sm='8' className='text-left'>
              <p>
                <a href={gear.url} target='_blank' rel='noopener noreferrer'>
                  <strong>{gear.title}</strong>
                </a>
              </p>
              <p>Brand: {gear.brand}</p>
              <p>Desc: <MultiLineText text={gear.desc} /></p>
              <p>Rating(1-5):
                <Rating rating={gear.rating} />
              </p>
              <p>
                <Button outline className='btn Extlink' onClick={this.handleClick} value={gear.url}>View in Amazon</Button>
              </p>
              <br />
              <hr />
              <br />
            </Col>
                                  </Row>))
        }
          <div>
            <br />
            <br />
            <strong>(*)As an Amazon Associate this site earns from qualifying purchases. Your support is appreciated.</strong>
          </div>
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Gear)
